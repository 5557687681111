import { AppManifestBuilder } from '@wix/yoshi-flow-editor';
import { ControllerType, EditorAppContext, WixCodeRole } from '../types';
import { openSearchResultsWidgetSettingsPanel } from '../searchResultsWidgetSettings';
import { openSearchModalSettingsPanel } from '../searchModalSettings/searchModalSettings';

export function overrideSearchButtonGfpp(
  appContext: EditorAppContext,
  appManifestBuilder: AppManifestBuilder,
): AppManifestBuilder {
  const { translate, reportError, isClassicEditor } = appContext;

  return appManifestBuilder.configureWidget(
    ControllerType.SearchButton,
    (widgetBuilder) => {
      return widgetBuilder.configureConnectedComponents(
        WixCodeRole.SearchButton,
        (connectedComponentsBuilder) => {
          connectedComponentsBuilder
            .gfpp()
            .set('mainAction1', {
              label: translate('searchButton.gfpp.editSearchSuggestions'),
              async onClick() {
                try {
                  await openSearchModalSettingsPanel(appContext);
                } catch (e) {
                  reportError(e);
                }
              },
            })
            .set('mainAction2', {
              label: translate('searchButton.gfpp.manageSearchResults'),
              async onClick() {
                try {
                  await openSearchResultsWidgetSettingsPanel(appContext);
                } catch (e) {
                  reportError(e);
                }
              },
            })
            .set('design', {
              behavior: isClassicEditor ? 'DEFAULT' : 'HIDE',
            });
        },
      );
    },
  );
}
